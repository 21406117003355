<template>
  <b-form-group
    label="Dönem (Yıl)"
    label-for="year"
  >
    <validation-provider
      #default="{ errors }"
      name="Dönem (Yıl)"
      rules="required"
    >
      <cleave
        id="year"
        v-model="itemData.year"
        class="form-control"
        :raw="false"
        :options="options.year"
        placeholder="Dönem (Yıl)"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'YearText',
  components: {
    BFormGroup,
    Cleave,
    ValidationProvider,
  },
  data() {
    return {
      options: {
        year: {
          blocks: [4],
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['reports/dataItem']
    },
  },
}
</script>
