<template>
  <b-card title="Dosya Eki">
    <div
      v-if="itemData.filename"
      class="d-flex justify-content-center"
    >
      <div class="rounded width-200 text-center mb-2 p-2 border border-1">
        <div class="border border-1 py-3 rounded">
          <FeatherIcon
            icon="FileIcon"
            size="24"
          />
        </div>
        <b-button
          class="mt-1"
          variant="secondary"
          size="sm"
          block
          :href="fileUrl + '/media/reports/' + itemData.filename"
          target="_blank"
        >
          <FeatherIcon icon="DownloadIcon" />
          Görüntüle
        </b-button>
        <b-button
          block
          class="mt-1"
          variant="flat-danger"
          size="sm"
          @click="removeFile(itemData.id_docs)"
        >
          <FeatherIcon icon="TrashIcon" />
          Dosyayı Sil
        </b-button>
      </div>
    </div>
    <div
      v-else
      class="text-center mt-1"
    >
      <b-button
        variant="primary"
        @click="setModal"
      >
        <FeatherIcon icon="UploadIcon" />
        Dosya Yükle
      </b-button>
    </div>
    <docs-upload
      v-if="uploadSettings.modalStatus"
      :modal-status="uploadSettings.modalStatus"
      :close-modal="closeModal"
      :folder="uploadSettings.folder"
      component="reports"
      :item-id="itemData.id"
      @uploadFile="setUploadFile"
    />
  </b-card>
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue'
import DocsUpload from '@/layouts/components/common/DocsUpload.vue'

export default {
  name: 'FileCard',
  components: {
    BCard,
    BButton,
    DocsUpload,
  },
  data() {
    return {
      fileUrl: this.$store.state.app.baseURL,
      uploadSettings: {
        modalStatus: false,
        folder: null,
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['reports/dataItem']
    },
  },
  methods: {
    setModal() {
      this.uploadSettings = {
        modalStatus: true,
        folder: 'reports',
      }
    },
    closeModal() {
      this.uploadSettings = {
        modalStatus: false,
        folder: null,
      }
    },
    removeFile(id) {
      this.$store.dispatch('fileUpload/removeData', id)
        .then(response => {
          if (response.status) {
            this.itemData.filename = null
            this.itemData.filetype = null
            this.itemData.id_docs = null
            this.itemData.fileData = null
          }
        })
    },
    setUploadFile(item) {
      this.itemData.filename = item.filename
      this.itemData.filetype = item.filetype
      this.itemData.fileData = item
      this.uploadSettings.modalStatus = false
    },
  },
}
</script>
