<template>
  <b-card>
    <b-alert
      v-if="dataList.length === 0"
      variant="info"
      show
    >
      <div class="alert-body text-center">
        Henüz hiç öne çıkan veri eklenmemiş. Öne çıkan veri eklemek için "İçerik Ekle" butonuna tıklayın.
      </div>
    </b-alert>
    <b-row
      v-for="(item,index) in dataList"
      :key="index"
    >
      <b-col>
        <b-form-group
          label="Etiket"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="dataList[index].title"
            placeholder="Etiket"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Değer"
          label-for="value"
        >
          <b-input-group>
            <b-form-input
              id="value"
              v-model="dataList[index].value"
              placeholder="Değer"
            />
            <b-input-group-append
              v-if="dataList[index].symbol"
              is-text
            >
              <FeatherIcon
                v-if="dataList[index].symbol === 'up'"
                icon="ChevronUpIcon"
                size="18"
                class="text-success"
              />
              <FeatherIcon
                v-if="dataList[index].symbol === 'down'"
                icon="ChevronDownIcon"
                size="18"
                class="text-danger"
              />
              <FeatherIcon
                v-if="dataList[index].symbol === 'straight'"
                icon="MinusIcon"
                size="18"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Sembol"
          label-for="symbol"
        >
          <v-select
            id="symbol"
            v-model="dataList[index].symbol"
            :options="symbols"
            placeholder="Seçiniz"
            :reduce="i => i.value"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="auto"
        class="d-flex align-items-center"
      >
        <b-button
          variant="danger"
          pill
          class="btn-icon"
          @click="removeItem(index)"
        >
          <FeatherIcon icon="TrashIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-button
      variant="danger"
      size="sm"
      pill
      @click="addItem"
    >
      <FeatherIcon icon="PlusIcon" /> İçerik Ekle
    </b-button>
  </b-card>
</template>
<script>
import {
  BCard, BAlert, BButton, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ReportHighlights',
  components: {
    BCard,
    BAlert,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  data() {
    return {
      symbol: null,
      symbols: [
        {
          label: 'Yukarı Yönlü',
          value: 'up',
        },
        {
          label: 'Aşağı Yönlü',
          value: 'down',
        },
        {
          label: 'Sabit / Düz',
          value: 'straight',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['reportHighlights/dataList']
    },
    dataItem() {
      return this.$store.getters['reportHighlights/dataItem']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    addItem() {
      this.dataList.push({
        id: null,
        title: null,
        value: null,
        symbol: null,
        id_reports: this.$route.params.id,
        id_users: null,
      })
    },
    removeItem(key) {
      if (this.dataList[key].id) {
        this.$store.dispatch('reportHighlights/removeData', this.dataList[key].id)
          .then(response => {
            if (response.status) {
              this.dataList.splice(key, 1)
            }
          })
      } else {
        this.dataList.splice(key, 1)
      }
    },
    getDataList() {
      this.$store.dispatch('reportHighlights/getDataList', {
        where: {
          'report_highlights.id_reports': this.$route.params.id,
        },
      })
    },
  },
}
</script>
