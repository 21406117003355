<template>
  <b-form-group
    label="İçerik"
    label-for="content"
  >
    <validation-provider
      #default="{ errors }"
      name="İçerik"
      rules="required"
    >
      <quill-editor
        v-model="dataItem.content"
        :options="editorOption"
        class="bg-white text-black-50"
      >
        <div
          id="content_toolbar"
          slot="toolbar"
        >
          <editor-toolbar />
        </div>
      </quill-editor>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import EditorToolbar from '@/layouts/components/common/EditorToolbar.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'Content',
  components: {
    BFormGroup,
    quillEditor,
    ValidationProvider,
    EditorToolbar,
  },
  data() {
    return {
      required,
      editorOption: {
        modules: {
          toolbar: '#content_toolbar',
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['reports/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
