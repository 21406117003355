<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Genel Bilgi</span>
        </template>
        <b-card>
          <b-row>
            <b-col cols="12">
              <title-text />
            </b-col>
            <b-col cols="12">
              <summary-content />
            </b-col>
            <b-col cols="12">
              <content-text />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <year-text />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <month-select />
            </b-col>
            <b-col
              cols="12"
            >
              <status-select />
            </b-col>
          </b-row>
        </b-card>
        <b-row class="match-height">
          <b-col
            cols="12"
            md="6"
          >
            <image-card />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <file-card />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :disabled="!dataItem.id">
        <template #title>
          <feather-icon icon="PieChartIcon" />
          <span>Öne Çıkanlar</span>
        </template>
        <report-highlights v-if="dataItem.id" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BTabs, BTab,
} from 'bootstrap-vue'
import TitleText from '@/views/Admin/Reports/elements/TitleText.vue'
import SummaryContent from '@/views/Admin/Reports/elements/SummaryContent.vue'
import ContentText from '@/views/Admin/Reports/elements/ContentText.vue'
import YearText from '@/views/Admin/Reports/elements/YearText.vue'
import MonthSelect from '@/views/Admin/Reports/elements/MonthSelect.vue'
import StatusSelect from '@/views/Admin/Reports/elements/StatusSelect.vue'
import ImageCard from '@/views/Admin/Reports/elements/ImageCard.vue'
import FileCard from '@/views/Admin/Reports/elements/FileCard.vue'
import ReportHighlights from '@/views/Admin/Reports/ReportHighlights.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    TitleText,
    SummaryContent,
    ContentText,
    YearText,
    MonthSelect,
    StatusSelect,
    ImageCard,
    FileCard,
    ReportHighlights,
  },
  computed: {
    dataItem() {
      return this.$store.getters['reports/dataItem']
    },
  },
}
</script>
